import styled from "styled-components"
import { Field } from "formik"
import { DoubleGrid } from "../../../globalStyle"

export const Container = styled.div`
  margin: 50px 0 50px 0;
  width: 1470px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.average};
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
  }
`

export const ContactGrid = styled(DoubleGrid)``

export const TalkWrapper = styled.div`
  width: 1470px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextInput = styled(Field)`
  padding: 15px;
  border: 1px solid #c2d7fc;
  border-radius: 25px;
  outline: none;
  transition: 0.5s;
  &:active,
  &:focus {
    border-color: ${props => props.theme.colors.orange};
  }
`
export const TextArea = styled(Field)`
  resize: none;
  padding: 15px;
  border: 1px solid #c2d7fc;
  border-radius: 25px;
  outline: none;
  transition: 0.5s;
  height: 170px;
  &:active,
  &:focus {
    border-color: ${props => props.theme.colors.orange};
  }
`

export const FileInput = styled(Field)`
  color: ${props => props.theme.colors.darkBlue};
  font-size: ${props => props.theme.fontSizes.average};
  display: none;
`

export const Label = styled.label`
  margin-top: 15px;
  font-size: ${props => props.theme.fontSizes.average};
  color: ${props => props.theme.colors.darkBlue};
  font-weight: ${props => props.theme.fontWeight.normal};
`
export const Info = styled.span`
  font-size: ${props => props.theme.fontSizes.verySmall};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.darkBlue};
`

export const FileInputLabel = styled(Label)`
  border: 2px solid ${props => props.theme.colors.orange};
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  width: 110px;
  cursor: pointer;
`

export const RadioLabel = styled(Label)`
  padding: 3px;
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeight.thin};
  padding-left: 36px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span:after {
    display: block;
    top: 8px;
    left: 8px;
    width: 35%;
    height: 35%;
    border-radius: 50%;
    background: ${props => props.theme.colors.orange};
  }
  & input:checked ~ span {
    background-color: white;
  }
  & input:checked ~ span:after {
    display: block;
  }
  & span:after {
    top: 3px;
    left: 3px;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background: ${props => props.theme.colors.orange};
  }
`

export const Radio = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

export const Mark = styled.span`
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.darkBlue};

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`
