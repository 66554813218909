import React from "react"
import Head from "../components/Head"
import ContactForm from "../components/Sections/ContactForm"
import Layout from "../components/UI/Layout"

const Contact = () => {
  return (
    <Layout>
      <Head
        description="👨‍💻 Communication makes things work well! 📞 +48 609 38 05 04 📞+48 793 086 087 📩 office@oakfusion.pl 💡 Tell us about your project."
        pageTitle="Contact Us | Got An Idea? We Turn Words Into Code"
        keywords="software, oakfusion"
        externalScriptUrl="https://www.google.com/recaptcha/api.js"
      />
      <ContactForm />
    </Layout>
  )
}

export default Contact
