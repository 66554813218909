import React from "react"
import IPageTitle from "./types"
import { H2Title } from "../../../globalStyle"
import { Container } from "./style"

const PageTitle = (props: IPageTitle) => {
  const { title } = props
  return (
    <Container>
      <H2Title marginLeft="15px">{title}</H2Title>
    </Container>
  )
}

export default PageTitle
